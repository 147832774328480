// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OneToTwoBlock_6lBRg{display:flex;gap:2rem}@media(max-width:1279.98px){.OneToTwoBlock_6lBRg{flex-direction:column;gap:0}}@media(max-width:767.98px){.OneToTwoBlock_6lBRg{max-width:90vw}}.firstContainer_4pyVd>div{margin-bottom:0}@media(-ms-high-contrast:active),(-ms-high-contrast:none),(hover){.firstContainer_4pyVd:hover .image_61Sb\\+ img{border-radius:1.4rem;transform:scale(1.1)}}.firstContainer_4pyVd:has(+.secondContainer_tw5Ko .description_Q6Yjv:hover) .image_61Sb\\+ img{border-radius:1.4rem;transform:scale(1.1)}@media(max-width:767.98px){.firstContainer_4pyVd{display:none}}.image_61Sb\\+{border-radius:1.4rem;height:100%;max-height:32.5rem;max-width:32.5rem;overflow:hidden;width:32.5rem}.image_61Sb\\+ .nuxtImg_7tJwR{display:block;object-fit:cover;width:100%}.image_61Sb\\+ img{border-radius:1.4rem;height:32.5rem;transition:transform .4s cubic-bezier(.25,.25,.1,1);width:100%}@media(max-width:1279.98px){.image_61Sb\\+{height:100%;margin-bottom:1.2rem;max-height:none;max-width:none;width:100%}}@media(max-width:767.98px){.image_61Sb\\+ .image_61Sb\\+{display:none}}.description_Q6Yjv{max-width:55.5rem}.secondContainer_tw5Ko{display:flex;flex-direction:column;justify-content:space-between;max-width:calc(100% - 34.5rem)}@media(max-width:1279.98px){.secondContainer_tw5Ko{max-width:none}}.cardsContainer_HFglF{display:flex;flex-direction:row;max-width:100%}.cardsContainer_HFglF .card_Eekrs{max-width:50%}.cardsWrapper_Pu7c7{display:flex;flex-direction:column;justify-content:space-between}.cardsTitle_\\+rw-W{font-size:1.8rem;font-weight:500;letter-spacing:-.072rem;line-height:2.16rem;margin-bottom:2.4rem;margin-top:auto;padding-top:3.2rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header-h": "6.4rem",
	"header-h-tablet": "5.6rem",
	"header-h-mobile": "56px",
	"footer-h": "61.2rem",
	"mobile": "0",
	"tablet-sm": "768px",
	"tablet": "1024px",
	"laptop": "1280px",
	"desktop": "1440px",
	"large-desktop": "1920px",
	"OneToTwoBlock": "OneToTwoBlock_6lBRg",
	"firstContainer": "firstContainer_4pyVd",
	"image": "image_61Sb+",
	"secondContainer": "secondContainer_tw5Ko",
	"description": "description_Q6Yjv",
	"nuxtImg": "nuxtImg_7tJwR",
	"cardsContainer": "cardsContainer_HFglF",
	"card": "card_Eekrs",
	"cardsWrapper": "cardsWrapper_Pu7c7",
	"cardsTitle": "cardsTitle_+rw-W"
};
module.exports = ___CSS_LOADER_EXPORT___;
